/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import MainLayout from '../containers/SidebarLayout';
import { useDispatch, useSelector } from 'react-redux';
import { selectPost } from '../redux/reducers/post/selectors';
import { fetchFeed } from '../redux/reducers/post/actions';

import PostCard from '../components/PostCard';

export default function IndexPage({ cookies }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { feed, postLoading } = useSelector(selectPost);

  useEffect(() => {
    fetchFeed()(dispatch);
  }, []);

  const renderPosts = () => {
    return (
      <Grid columns={[1]}>
        {postLoading && feed?.length === 0
          ? [...Array(4).keys()].map((key) => <PostCard key={key} />)
          : feed.map((item) => (
              <PostCard
                key={item?.id}
                post={{
                  ...item.post,
                  user: item.author,
                  category: item.category,
                }}
              />
            ))}
      </Grid>
    );
  };

  return (
    <MainLayout title={t('Posts')} popularAuthors cookies={cookies}>
      {renderPosts()}
    </MainLayout>
  );
}
